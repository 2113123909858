<template>
    <el-container>
        <el-aside width="500px">
            <el-timeline>
                <el-timeline-item :timestamp="record.time" placement="top" v-for="record in replays" :key="record">
                <el-card style="width:400px">
                    <h4>{{record.discribe}}</h4>
                    <el-tooltip content="点击加载回放" placement="right-end"><el-image :src="getRES + 'images/kc-replay/' + record.recordimg" @click="getKC3ReplayerUrl(record)"/></el-tooltip>
                </el-card>
                </el-timeline-item>
            </el-timeline>
        </el-aside>
        <el-main>
            <el-affix :offset="110" style="width:100%; max-width:1200px">
            <el-card class="box-card" :body-style="{ 'background-color':'#DDEEFF' }" style="width:100%">
            <template #header>
                <div class="card-header">
                <span>{{cardtitle}}</span>
                </div>
            </template><iframe :src="kc3relayerurl" width="100%" height="720px" frameBorder="0" scrolling="auto"></iframe>
            </el-card>
            </el-affix>
        </el-main>
    </el-container>
</template>

<script>
export default {
    data() {
        return {
            replays: null,
            kc3relayerurl: "https://kc3kai.github.io/kancolle-replay/battleplayer.html",
            cardtitle: "KanColle Battle Replayer"
        }
    },
    methods: {
        getKC3ReplayerUrl(record){
            this.kc3relayerurl='https://kc3kai.github.io/kancolle-replay/battleplayer.html?fromImg=' + location.protocol+'//'+location.host + this.getRES + 'images/kc-replay/' + record.recordimg
            this.cardtitle=record.discribe
        }
    },
    mounted() {
      this.axios.get(process.env.VUE_APP_API+'v1/kancolle/replays')
        .then(response => {
            this.replays = response.data
            
            if(this.$route.query.replay != null) {
                this.replays.forEach(replay => { 
                    if(replay.recordimg == this.$route.query.replay){
                        setTimeout(() => {
                            this.getKC3ReplayerUrl(replay)
                        }, 500);
                    }
                })
            }

        })
    },
    computed: {
        getRES() {return process.env.VUE_APP_RES}
    }
}
</script>
